/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-04-06",
    versionChannel: "nightly",
    buildDate: "2024-04-06T00:05:21.603Z",
    commitHash: "4c8d0704dbf1975e61edb180a80d75ad06d0eca7",
};
